<template>
	<div class="instantOrder">
		<el-card class="box-card father-card" shadow="never">
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<el-form-item label="订单编号">
						<el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item label="机构">
						<el-select v-model="form.region" placeholder="请选择机构名称" clearable>
							<el-option v-for="(regionItem,index) in orderCompanyList" :key="index"
								:label="regionItem.companyName" :value="regionItem.uuid"></el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="订单开始结束时间">
                        <WTimer ref="WTimer" v-model="form" :startEnd="{start:'startOrderDate',end:'endOrderDate'}" />
                    </el-form-item>
					<!-- <el-form-item label="订单开始时间" prop="disinfectTime">
						<el-date-picker v-model="form.startOrderDate" type="datetime" placeholder="开始时间"
							label-width="10px" clearable format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="到" prop="disinfectTime">
						<el-date-picker v-model="form.endOrderDate" type="datetime" placeholder="结束时间" clearable
							format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item> -->
					<el-form-item>
						<el-button type="success" icon="el-icon-search" @click="search">搜索</el-button>
						<el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>
						<el-button type="info" icon="el-icon-share" @click="toExcel">导出</el-button>
					</el-form-item>
					<!-- 按钮区域 -->
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%"
					highlight-current-row @current-change="addremarks" stripe border :header-cell-style="rowClass">
					<!-- <el-table-column prop="orderNo" label="展开" align="center"  type="expand" >
			  <template slot-scope="scope" >
				 {{scope.row.remark}}
				  </template>
		 </el-table-column> -->
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="onePayNo" label="预付流水号" align="center"></el-table-column>
					<el-table-column prop="secondPayNo" label="尾款流水号" align="center"></el-table-column>
					<el-table-column prop="orderDate" label="订单时间" align="center"></el-table-column>
					<!-- <el-table-column prop="bargainStatus" label="是否开发票" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.invoice == 0">{{ "否" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.invoice == 1">{{ "是" }}
							</el-tag>
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="orderCompanyName" label="所属机构" align="center"></el-table-column> -->
					<el-table-column prop="applicationName" label="订单来源" align="center">
						<template slot-scope="scope">
							<div type="success" effect="dark">{{scope.row.applicationName}}</div>
						</template>
					</el-table-column>
					<!-- orderType -->
					<el-table-column prop="orderType" label="订单类型" align="center">
						<template slot-scope="scope">
							<div effect="dark" v-if="scope.row.orderType==='0'">{{ '立即用车' }}</div>
							<div type="success" effect="dark" v-else>{{ '预约用车' }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="memberName" label="下单用户" align="center"></el-table-column>
					<el-table-column prop="orderReserveDate" label="预约时间" align="center"></el-table-column>
					<el-table-column prop="transferVehicle" label="转运类型" align="center"></el-table-column>
					<el-table-column prop="orderPredictPayActual" label="已付预付费" align="center">
						<template slot-scope="scope">
							{{scope.row.orderPredictPayActual || 0}}
						</template>
					</el-table-column>
					<el-table-column prop="orderFactFeeActual" label="已付结单费" align="center">
						<template slot-scope="scope">
							{{scope.row.orderFactFeeActual || 0}}
						</template>
					</el-table-column>
					<el-table-column prop="sumPayFee" label="订单总付费" align="center"></el-table-column>
					<el-table-column prop="bargainFee" label="议价费用" align="center"></el-table-column>
					<!-- <el-table-column prop="bargainStatus" label="是否议价" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.bargainStatus === '0'">{{ "否" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.bargainStatus === '1'">{{ "是" }}
							</el-tag>
						</template>
					</el-table-column> -->
					<!-- <el-table-column label="轨迹回放" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="ordercha(scope.row)" type="success">查看轨迹</el-button>
            </template>
          </el-table-column> -->
					<!-- 	<el-table-column label="详情" align="center" min-width="200px">
						<template slot-scope="scope">
							<el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
							<el-button size="mini" type="success" @click="invoiceClick(scope.row)">是否开发票</el-button>
						</template>
					</el-table-column> -->
				</el-table>
				<!-- 分页区域 -->
				<el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.PageNo" :page-sizes="[10,15, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				<!-- 订单详情弹出框 -->
				<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
					<span slot="title" class="dialog-Title">即时订单详情</span>
					<el-form :model="orderForm" inline ref="orderForm">
						<div class="fpddcolor">
							<!--        <div class="fpddcolor-title" style="display: flex; justify-content: space-between;">
                      <div v-if="orderForm.orderType == 0">订单类型：立即用车</div><div v-if="orderForm.orderType == 1">订单类型：预约用车</div>
                      <div v-if="orderForm.status == 1">订单状态：已派单</div>
                      <div v-if="orderForm.status == 2">订单状态：已接单</div>
                      <div v-if="orderForm.status == 3">订单状态：变更</div>
                      <div v-if="orderForm.status == 4">订单状态：已支付</div>
                      <div v-if="orderForm.status == 5">订单状态：取消订单</div>
                      <div v-if="orderForm.status == 6">订单状态：全额退款</div>
                      <div v-if="orderForm.status == 7">订单状态：完成</div>
                      <div v-if="orderForm.status == 8">订单状态：代付预付费</div>
                      <div v-if="orderForm.status == 9">订单状态：到达出发地</div>
                      <div v-if="orderForm.status == 10">订单状态：到达目的地</div>
                      <div v-if="orderForm.status == 11">订单状态：非正常完成</div>
                      <div v-if="orderForm.status == 12">订单状态：已退预付</div>
                      <div v-if="orderForm.status == 13">订单状态：已退结单</div>
                      </div> -->
							<div class="daijiaocolor">
								<div class="daijiaocolor-left" style="padding-left: 10px;"><img
										src="../../images/us.png">客户信息</div>
							</div>
							<div style="background-color: #fff;display: flex;">
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										下单人姓名</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.memberName}}</div>
								</div>
								<div class="cflex">
									<div style="background-color: #F3F6FA;padding: 10px 20px;width: 100px;">手机号码</div>
									<div style="padding: 10px 20px;">{{orderForm.memberMobile}}</div>
								</div>
							</div>
							<div>
								<div class="daijiaocolor">
									<div class="daijiaocolor-left" style="padding-left: 10px;"><img
											src="../../images/us.png">患者信息</div>
								</div>
								<div style="display: flex;background-color: #fff;border-bottom: #F3F6FA 2px solid;">
									<div class="cflex">
										<div
											style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
											患者姓名</div>
										<div style="padding: 10px 20px;width: 200px;">
											{{orderForm.patientName || '暂无信息'}}
										</div>
									</div>
									<div class="cflex">
										<div
											style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
											患者年龄</div>
										<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientAge || '暂无信息'}}
										</div>
									</div>
									<div class="cflex">
										<div
											style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
											患者手机号码</div>
										<div style="padding: 10px 20px;width: 200px;">
											{{orderForm.patientMobile || '暂无信息'}}
										</div>
									</div>
								</div>
								<div style="display: flex;background-color: #fff;">
									<div class="cflex">
										<div
											style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
											病情描述</div>
										<div style="padding: 10px 20px;">{{orderForm.patientConditionRemark || '暂无信息'}}
										</div>
									</div>
								</div>
							</div>
							<div class="daijiaocolor">
								<div class="daijiaocolor-left" style="padding-left: 10px;"><img
										src="../../fonts/home.png">订单信息</div>
							</div>
							<div class="fpddcolor-in"
								style="display: flex;background-color: #fff;border-bottom: #F3F6FA 2px solid;">
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										下单时间</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.createTime}}</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										订单来源</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.applicationName}}</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										订单编号</div>
									<div style="padding: 10px 20px;">{{orderForm.orderNo}}</div>
								</div>
							</div>
							<div class="fpddcolor-in"
								style="display: flex;background-color: #fff;border-bottom: #F3F6FA 2px solid;">
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										转运类型</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.transferVehicle}}</div>
								</div>

								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										订单类型</div>
									<!-- <div style="padding: 10px 20px;">{{orderForm.orderNo}}</div> -->
									<div style="padding: 10px 20px;width: 200px;" v-if="orderForm.orderType == 0">立即用车
									</div>
									<div style="padding: 10px 20px;width: 200px;" v-if="orderForm.orderType == 1">预约用车
									</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										实跑公里</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderFactMile}}</div>
								</div>
							</div>
							<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										预估金额</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderPredictFee}}</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										预估预付</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderPredictPay}}</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										预估公里</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.predictMile}}</div>
								</div>
							</div>
							<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">

								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										议价费用</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.bargainFee}}</div>
								</div>
							</div>
							<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">

								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										已付预付</div>
									<div style="padding: 10px 20px;width: 200px;">
										{{orderForm.orderPredictPayActual || 0}}
									</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										已付结单</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderFactFeeActual || 0}}
									</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										订单总付</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.sumPayFee || 0}}</div>
								</div>

							</div>
							<div class="cflex" style="align-items: center;">
								<div style="background-color: #F3F6FA;width: 114px;text-align: center;">订单备注</div>
								<div style="background-color: #fff;padding: 10px;width: 100%;">
									{{orderForm.remark || '暂无备注信息'}}
								</div>
							</div>
							<div class="daijiaocolor">
								<div class="daijiaocolor-left" style="padding-left: 10px;"><img
										src="../../images/carblue.png">车辆信息 </div>
								<div>{{orderForm.orderCompanyName}}</div>
							</div>
							<div style="background-color: #fff;padding: 10px 10px 0 10px;">
								<div>
									<el-row class="dialog-Title">
										<el-form-item label="车牌号:">
											<el-input v-model="orderForm.carNum" autocomplete="off" disabled clearable>
											</el-input>
										</el-form-item>
										<el-form-item label="司机名称:">
											<el-input v-model="orderForm.driverName" autocomplete="off" disabled
												clearable></el-input>
										</el-form-item>
										<el-form-item label="转运类型:">
											{{orderForm.transferVehicle}}
										</el-form-item>
										<el-form-item label="司机电话:">
											<el-input v-model="orderForm.driverPhone" autocomplete="off"
												:disabled="isDisable" clearable></el-input>
										</el-form-item>
									</el-row>
								</div>
								<div>
									<el-row class="dialog-Title">
										<el-form-item label="随行医生:">
											{{orderForm.doctorList}}
										</el-form-item>
										<el-form-item label="随行护士:">
											{{orderForm.nurseList}}
										</el-form-item>
										<el-form-item label="护送人员:">
											{{orderForm.escortList}}
										</el-form-item>
										<el-form-item label="搬抬人员:">
											{{orderForm.raiseList}}
										</el-form-item>
									</el-row>
								</div>
							</div>

							<div class="daijiaocolor">
								<div class="daijiaocolor-left" style="padding-left: 10px;"><img
										src="../../fonts/location@2x.png">位置信息</div>
							</div>
							<div class="fpddcolor-low" style="background-color: #fff;padding: 10px;">
								<div style="color:#0097fe;display: flex;align-items: center;"><img
										src="../../fonts/operation@3x.png" style="margin-right: 10px;">
									<div style="width: 60px;">起始地:</div>
									<el-input v-model="orderForm.startAddress" autocomplete="off" :disabled="isDisable"
										clearable></el-input>
								</div>
								<div style="color:#FF7F50;display: flex;align-items: center;"><img
										src="../../images/zd.png" style="margin-right: 10px;">
									<div style="width: 60px;">目的地:</div>
									<el-input v-model="orderForm.endAddress" autocomplete="off" :disabled="isDisable"
										clearable></el-input>
								</div>
							</div>
						</div>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
					</div>
				</el-dialog>
				<el-dialog :visible.sync="orderDialogremark" class="order-dialog">
					<el-form :model="addupremark" inline ref="addupremark">
						<div class="xbrogcorf">
							<div style="display: flex;">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单编号</div>
								<input placeholder="请输入订单编号" v-model="addupremark.orderNo" clearable />
							</div>
						</div>
						<el-input type="textarea" placeholder="请输入备注内容" v-model="addupremark.remark" show-word-limit
							autosize style="height: 200px;"> </el-input>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="orderDialogaddremark()">确 认</el-button>
					</div>
				</el-dialog>

				<el-dialog :visible.sync="invoiceShow" class="order-dialog" width="30%" title="是否开发票">
					<div>
						<span style="margin-right: 20px;">开发票:</span>
						<el-select v-model="invoiceValue" placeholder="请选择" @change="invoiceSelect">
							<el-option v-for="item in invoiceOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="invoiceChange">确 认</el-button>
					</div>
				</el-dialog>

			</el-row>
		</el-card>
		<div class="mapContainer" id="mapContainer"></div>
	</div>
</template>
<script>
import loginVue from '../login.vue'
import store from '@/store/index.js'
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'instantOrder',
  components: {
    WTimer
  },
  data () {
    return {
      List: [], // 表格数据源
      orderCompanyList: [], // 机构列表
      paymentStatusList: [{
        status: 0,
        text: '新增'
      }, // 支付状态列表
      {
        status: 1,
        text: '派单'
      },
      {
        status: 2,
        text: '已接单'
      },
      {
        status: 3,
        text: '变更'
      },
      {
        status: 4,
        text: '已支付'
      },
      {
        status: 5,
        text: '取消订单'
      },
      {
        status: 6,
        text: '全额退款'
      },
      {
        status: 7,
        text: '完成'
      },
      {
        status: 8,
        text: '代付预付费'
      },
      {
        status: 9,
        text: '到达出发地'
      },
      {
        status: 10,
        text: '到达目的地'
      },
      {
        status: 11,
        text: '不正常完成'
      },
      {
        status: 12,
        text: '预付退款'
      },
      {
        status: 13,
        text: '结单退款'
      }
      ],
      form: {
        orderNo: '', // 订单编号
        region: '', // 机构
        startOrderDate: '', // 开始时间
        endOrderDate: '' // 结束时间
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderDialogremark: false,
      addupremark: {
        orderNo: '',
        remark: ''
      },
      orderForm: {},
      remakestauts: '', // 控制备注值
      isDisable: true,
      dialogVisible: false,
      centerlongitude: 39.984104, // 地图中心经度,
      centerlatitude: 116.307503, // 地图中心纬度
      carNumber: [],
      driverNumber: [],
      doctor: [],
      escorts: [],
      lifting: [],
      nurse: [],
      invoiceShow: false, // 发票dialog
      invoiceOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      invoiceValue: '', // 是否开发票
      rows: {},
      fileName: '',
      selectedWorkName: {
        companyName: ''
      },
      comName: ''
    }
  },
  created () {
    this.getOrderList()
    this.getorderCompany()
  },
  mounted () {
    // 创建地图

  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getOrderList()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      await this.getOrderList()
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
    },
    // 获取所有实时订单
    async getOrderList () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectAllOrderList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        invoice: '1'
      })
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      console.log(res)
    },
    // reset () {
    //   this.form.orderType = ''
    //   this.form.status = ''
    //   this.form.region = ''
    //   this.form.orderNo = ''
    //   this.form.startOrderDate = ''
    //   this.form.endOrderDate = ''
    //   this.paging.pageSize = 10
    //   this.paging.PageNo = 1
    //   this.search()
    // },
    // 修改（添加）备注 弹出框
    addremarks (e) {
      if (e) {
        this.remakestauts = e
      }
    },
    addremark () {
      const rs = this.remakestauts
      console.log(this.remakestauts)
      if (this.remakestauts) {
        this.orderDialogremark = true
        this.addupremark.orderNo = rs.orderNo
        this.addupremark.remark = rs.remark
      } else {
        return this.$message.error('请选择您要编辑的订单')
      }
    },
    // 修改（添加）备注
    async orderDialogaddremark () {
      // if(this.addupremark.remark == "" || this.addupremark.remark == null)  return this.$message.error('请输入备注信息')
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/updateTinfoOrderRemark', {
        orderNo: this.addupremark.orderNo,
        remark: this.addupremark.remark
      })
      if (res.statusCode !== '200') return this.$message.error('编辑失败')
      this.orderDialogremark = false
      this.getOrderList()
      return this.$message.success('编辑成功')
    },
    handleClose () {

    },
    // 查看轨迹
    // ordercha(e){

    // },

    // 点击搜索按钮触发
    async search () {
      // var date = new Date(this.form.startOrderDate)
      // var y = date.getFullYear()
      // var m = date.getMonth() + 1
      // m = m < 10 ? ('0' + m) : m
      // var d = date.getDate()
      // d = d < 10 ? ('0' + d) : d
      // const time = y + '-' + m + '-' + d
      // var daten = new Date(this.form.endOrderDate)
      // var yn = daten.getFullYear()
      // var mn = daten.getMonth() + 1
      // mn = mn < 10 ? ('0' + mn) : mn
      // var dn = daten.getDate()
      // dn = dn < 10 ? ('0' + dn) : dn
      // const etime = yn + '-' + mn + '-' + dn
      // var statime = ""
      // var endtime = ""
      // if (time != "NaN-NaN-NaN" && time != "") {
      // 	statime = time
      // }
      // if (etime != "NaN-NaN-NaN" && etime != "") {
      // 	endtime = etime
      // }
      // 判断有输入账号
      this.paging.PageNo = 1
      // if (this.form.orderNo === '' && this.form.applicationCode === '') return this.$message.error('请输入搜索内容')
      // 调接口，进行查询
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectAllOrderList', {
        status: this.form.status,
        orderType: this.form.orderType,
        region: this.form.region,
        pageSize: this.paging.pageSize,
        starOrderDate: this.form.startOrderDate,
        endOrderDate: this.form.endOrderDate,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        applicationCode: this.form.applicationCode,
        userName: this.form.memberName,
        userPhone: this.form.memberMobile,
        invoice: '1'
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      console.log(this.total)
    },
    // 点击查看详情按钮触发
    look (v) {
      this.getAllCarNumber(v)
      this.getAlldriverNumber(v)
      this.getAlldoctor(v)
      this.getAllnurse(v)
      this.getAlllifting(v)
      this.getAllEscorts(v)
      this.orderForm = v
      this.orderDialogFormVisible = true
    },

    // 获取车牌号--调试完成
    async getAllCarNumber (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleList', {
        corpId: v.orderCompany
      })
      if (res.result !== 'true') return this.$message.error('获取车牌号列表失败')
      this.carNumber = res.data
      // console.log(this.carNumber);
    },
    // 获取司机--调试完成
    async getAlldriverNumber (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDriver/getTInfoDriverList', {
        companyId: v.orderCompany
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取司机失败')
      this.driverNumber = res.data.records
    },
    // 获取随车医生--调试完成
    async getAlldoctor (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 0
      })
      if (res.statusCode !== '200') return this.$message.error('获取医生列表失败')
      this.doctor = res.data.records
    },
    // 获取随车护士--调试完成
    async getAllnurse (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 1
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取随车护士失败')
      this.nurse = res.data.records
    },
    // 获取所有搬抬人员--调试完成
    async getAlllifting (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 3
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取搬抬人员失败')
      this.lifting = res.data.records
      // console.log(res);
    },
    // 获取所有护送员--调试完成
    async getAllEscorts (v) {
      // console.log(this.allotForm.orderCompany);
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 2
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取护送员失败')
      this.escorts = res.data.records
      // console.log(res);
    },
    invoiceClick (row) { // 是否开发票
      console.log(row)
      this.invoiceShow = true
      this.rows = row
    },
    invoiceChange () {
      if (!this.invoiceValue) {
        return this.$message.error('请选择是否开发票')
      }
      this.$http.post('/userManageServer/pay/updateOrder', {
        invoice: this.invoiceValue,
        orderNo: this.rows.orderNo
      }).then(res => {
        this.$message.success(res.data.resultDesc)
        this.invoiceShow = false
        this.getOrderList()
        console.log(res, '请选择是否开发票')
      })
    },
    invoiceSelect (val) {
      console.log(val)
      this.invoiceValue = val
    },
    reset () { // 重置
      this.form.orderNo = ''
      this.form.region = ''
      this.form.startOrderDate = ''
      this.form.endOrderDate = ''
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.getOrderList()
    },
    toExcel () {
      var date = new Date(this.form.startOrderDate)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      var daten = new Date(this.form.endOrderDate)
      var yn = daten.getFullYear()
      var mn = daten.getMonth() + 1
      mn = mn < 10 ? ('0' + mn) : mn
      var dn = daten.getDate()
      dn = dn < 10 ? ('0' + dn) : dn
      const endtime = yn + '-' + mn + '-' + dn
      if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate) {
        this.fileName = this.selectedWorkName.companyName + '订单发票汇表'
        this.exportExcel(this.fileName + time + '到' + endtime)
      } else if (this.selectedWorkName.companyName && this.form.startOrderDate == '' && this.form.endOrderDate ==
					'') {
        this.fileName = this.selectedWorkName.companyName + '订单发票汇表'
        this.exportExcel(this.fileName)
      } else if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate == '') {
        this.fileName = this.selectedWorkName.companyName + '订单发票汇表'
        this.exportExcel(this.fileName + time)
      } else if (this.selectedWorkName.companyName && this.form.endOrderDate && this.form.startOrderDate == '') {
        this.fileName = this.selectedWorkName.companyName + '订单发票汇表'
        this.exportExcel(this.fileName + endtime)
      } else {
        this.fileName = '订单发票总汇表'
        this.exportExcel(this.fileName)
      }
    },
    async exportExcel (value) {
      if (this.selectedWorkName.companyName) {
        this.comName = this.selectedWorkName.companyName
      } else {
        this.comName = '' // 全机构
      }
      const data = {
        orderCompanyId: this.form.region,
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        starOrderDate: this.form.startOrderDate,
        endOrderDate: this.form.endOrderDate,
        invoice: '1'
      }
      // http://192.168.123.190:9000 /userManageServer/pay/selectAllOrderList
      await this.$http.post('/userManageServer/pay/ToExcel', data, {
        responseType: 'blob'
      }).then(res => {
        console.log(res.data)
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        // 第一种
        // '订单汇表.xlsx'
        const fileName = value
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      })
    }
  }

}
</script>

<style lang="less" scoped>
	.instantOrder {
		width: 100%;
		height: 100%;

		.father-card {

			.tab {
				/deep/ .el-table {
					margin-bottom: 20px;

					.el-table__row--striped td {
						background-color: #e4eaec !important;
					}

					.current-row>td {
						background-color: #66b1ff !important;
					}
				}

				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}

				.order-dialog {
					.xbrogcorf {
						background-color: #fff;
						display: flex;
						border-bottom: #F3F6FA 2px solid;

						input {
							border: 0;
							outline: none;
							padding-left: 20px;
						}
					}

					.dialog-Title {
						color: #0097fe;
						font-size: 20px;
						font-weight: 700;
					}

					/deep/ .el-form {
						background-color: #F3F6FA;

						.subtitle {
							color: #0097fe;
							font-size: 14px;
							font-weight: 700;
							// margin: 15px 0;
							margin-bottom: 20px;
							padding-left: 6px;
							border-left: 5px solid #0097fe;
						}

						input {
							border-top: 0px;
							border-right: 0px;
							border-left: 0px;
							border-bottom: 0px;
							background-color: #fff;
							font-weight: 700;
							color: #666666;
						}

						.addresscolor {
							font-size: 18px;
							// input{
							//     border-top: 0px;
							//     border-right: 0px;
							//     border-left: 0px;
							//     background-color: #fff;
							// }
						}

						.ordercolor {
							// background-color: #fff;
						}

						.fpddcolor {
							background-color: #F3F6FA;
							padding: 10px 10px;
							// line-height: 30px;
							font-weight: bold;
							border-top-left-radius: 12px;
							border-top-right-radius: 12px;

							.fpddcolor-title {
								border-bottom: 1px solid #666666;
								font-size: 18px;
								padding-bottom: 10px;
							}

							.fpddcolor-top {
								display: flex;
								justify-content: space-between;
								// margin-top: 10px;
								font-size: 18px;
							}

							.fpddcolor-in {
								// font-size: 16px;
							}

							.cflex {
								display: flex;
							}

							.fpddcolor-low {
								font-size: 18px;
								padding-bottom: 10px;

								img {
									width: 20px;
									height: 20px;
								}
							}
						}

						.daijiaocolor {
							display: flex;
							font-size: 18px;
							height: 45px;
							line-height: 45px;

							// justify-content: space-between;
							.daijiaocolor-left {
								margin-right: 20px;
							}

							img {
								width: 20px;
								height: 20px;
								margin-right: 12px;
							}

							input {
								height: 45px;
								width: 300px;
								border-top: 0px;
								border-left: 0px;
								border-right: 0px;
							}
						}

						.el-row {
							// flex: 1;
							display: flex;

							// justify-content: space-between;
							.el-form-item {
								// width: 50%;
								flex: 1;
								display: flex;

								// text-align: left;
								.el-form-item__content {
									flex: 1;

									.el-input {
										width: 100%;

										.el-input__inner {
											width: 100%;
										}
									}

									.el-select {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}

	}
</style>
